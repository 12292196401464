import React from 'react'
import '../../style/ApplicationDetail.css'
import company from '../../assets/Applications/Sanitary Ware/Large.jpg'

const SanitaryWare = () => {
    return (
        <>
            <div class="container">
                <div class="row application-detail">
                    <div class="col-md-6 col-sm-12">
                        <img src={company} alt="" />
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <h3>SANITARY WARE</h3>
                        <h5>Globally, our premium clay raw minerals contribute to enhancing the aesthetic appeal and structural stability of sanitaryware. </h5>
                        <p>Clay plays a vital role in production of sanitaryware, serving as the primary raw material. Its plasticity allows for easy
                            shaping using techniques like slip casting or pressing. After shaping, clay sanitaryware undergoes controlled drying to prevent
                            cracks. Firing at high temperatures strengthens the clay, while glazing enhances smoothness and impermeable finish. The resulting
                            sanitaryware exhibits resilience and longevity. Clay’s durability ensures reliable use, and decorative techniques enhance aesthetics.
                            Overall, clay’s properties contribute to the strength, functionality, and attractiveness of sanitaryware items.</p>

                        <p>Dashmesh Minerals understands the challenges of this industry and has been coming up with raw minerals solutions through the following:</p>
                        <ul>
                            <li>	State-of-the-art research and development facility  .</li>
                            <li>	Competent team working with a product-innovation-focused approach .</li>
                            <li>	Well-established mining network facilitating a diverse range of products .</li>
                            <li>	Streamlined logistics network ensures the precise and timely delivery .</li>
                                </ul>
                    </div>
                </div>
            </div>
        </>
    )

}


export default SanitaryWare;
