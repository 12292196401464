import React from "react";
import { Image, Row, Col, Tooltip, Card } from "antd";
import "./../../style/product.css";
import ps from "./../../assets/Products/Ball clay/Porcelain Insulator/ps.jpg";
import pl from "./../../assets/Products/Ball clay/Wall & Floor Tiles/pl.jpg";
import pa from "./../../assets/Products/Ball clay/Sanitary/ps.jpg";
import po from "./../../assets/Products/Ball clay/Refractory/ps.jpg";
import resi from "./../../assets/Products/Ball clay/icons/ctrl-resi-1.png";
import high from "./../../assets/Products/Ball clay/icons/High.png";
// import plasticity from "./../../assets/Products/Ball clay/icons/Plasticity.png";
import refractory from "./../../assets/Products/Ball clay/icons/Refractory.png";
import stabilty from "./../../assets/Products/Ball clay/icons/rheology-stabilty.png";
import strength from "./../../assets/Products/Ball clay/icons/strength.png";
import work from "./../../assets/Products/Ball clay/icons/workability.png";
import clay from "./../../assets/Products/Ball clay/Ball Clay img/Ball Clay.jpg";

const imageStyle = {
    margin: "0",

}; 
const BallClay = () => {
    return (
        <>
            <div className="container">
                <div className="product-title">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 mt-5">
                            <img alt="images" src={clay} style={imageStyle} />
                        </div>
                        <div className="col-lg-6 col-sm-12 mt-4 mb-5">
                            <h1>Ball Clay</h1>
                            <p>
                                Ball clay is a sedimentary clay, composed mainly of kaolinite, mica, and quartz. It is
                                highly valued in the ceramics industry for its excellent plasticity, high binding
                                properties, and firing behaviour, which leads it to play a crucial role in the
                                production of high-quality ceramics and is used in a wide range of applications,
                                including sanitaryware, whiteware, glaze formulation and refractory materials.
                            </p>
                            <div className="sio mt-4">
                                <p>
                                    Ball clay is a silicate clay rock of which kaolinite is the main coistituent<br />
                                    <span className="head">
                                        <b>Al<sub>2</sub> O<sub>3</sub> 2SiO<sub>2</sub> 2H<sub>2</sub>O </b>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pProperty">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <h2>Physical Properties</h2>
                            <p>
                                Ball Clay exhibits the following physical properties, which drive its application in
                                across various sectors.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row icons-container">
                    <div className="col-lg-2 col-md-12">
                        <img src={resi} alt="" />
                        <h4>
                        Controlled Residue
                        </h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={high} alt="" />
                        <h4>High plasticity</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={refractory} alt="" />
                        <h4>Refractory Nature</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={stabilty} alt="" />
                        <h4>Rheological Stability</h4>
                    </div>

                    <div className="col-lg-2 col-md-12">
                        <img src={strength} alt="" />
                        <h4>Strength</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={work} alt="" />
                        <h4>Excellent Workability

</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 ">
                        <div className="product-application mt-5">
                            <h2>Application</h2>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="porto-wrap-container">
                <Row>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper justify-center">
                                <Image src={pl} preview={false} />
                                <h3>TILES</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>TILES</b>
                                        <br />
                                        Ball clay exhibits a wide range of properties, which make it preferred
                                        ingredient in the ceramic industry for creating high-quality and aesthetically
                                        appealing tile products.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                                <Image src={pa} preview={false} />
                                <h3>SANITARYWARE </h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>SANITARYWARE </b>
                                        <br />
                                        Ball clay ensures high casting efficiency, whiteness, strong mechanical
                                        properties, favourable rheology, and water impermeability in sanitaryware.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                                <Image src={ps} preview={false} />
                                <h3>PORCELAIN INSULATORS </h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>PORCELAIN INSULATORS </b>
                                        <br />
                                        Ball clay improves plasticity, controls shrinkage, acts as a flux, enhances
                                        thermal shock resistance, and contributes to surface finish in making porcelain
                                        insulators.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                                <Image src={po} preview={false} />
                                <h3>REFRACTORY</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>REFRACTORY</b>
                                        <br />
                                        Ball clay can withstand extremely high temperatures and is used to make
                                        refractory products such as furnaces and kiln insulation.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div> */}

            <div className="porto-wrap-container mb-2">
                <Row>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper justify-center">
                            <Image src={pl} preview={false} />
                                <h3>TILES</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                    <b>TILES</b>
                                        <br />
                                        <p>
                                        Ball clay improves plasticity, controls shrinkage, acts as a flux, enhances
                                        thermal shock resistance, and contributes to surface finish in making porcelain
                                        insulators.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                            <Image src={pa} preview={false} />
                            <h3>SANITARYWARE </h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                    <b>SANITARYWARE </b>
                                        <br />
                                        <p>
                                        Ball clay ensures high casting efficiency, whiteness, strong mechanical
                                        properties, favourable rheology, and water impermeability in sanitaryware.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                            <Image src={ps} preview={false} />
                            <h3>PORCELAIN INSULATORS </h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                    <b>PORCELAIN INSULATORS </b>
                                        <br />
                                        <p>
                                        Ball clay improves plasticity, controls shrinkage, acts as a flux, enhances
                                        thermal shock resistance, and contributes to surface finish in making porcelain
                                        insulators.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                            <Image src={po} preview={false} />
                            <h3>REFRACTORY</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                    <b>REFRACTORY</b>
                                        <br />
                                        <p>
                                        Ball clay can withstand extremely high temperatures and is used to make
                                        refractory products such as furnaces and kiln insulation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default BallClay;