import Footer1 from "./Footer1"
import Footer2 from "./Footer2"

const FooterPage = () => {
    return (
        <>
            <Footer1 />
            <Footer2 />
        </>
    );
};
export default FooterPage;