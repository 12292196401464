import React from "react";
import { Image, Row, Col, Tooltip, Card } from "antd";
import "./../../style/product.css";


import powder from "./../../assets/Products/Kaoline/Paint & Coating/Powder.jpg";
import printing from "./../../assets/Products/Kaoline/Paper Industry/printingpaper.jpg";
import rubber from "./../../assets/Products/Kaoline/Rubber Industry/rubber.jpg";
import table from "./../../assets/Products/Kaoline/Tableware/2-2.png";

import disperse from "./../../assets/Products/Kaoline/icons/disperse-01.png";
import bright from "./../../assets/Products/Kaoline/icons/very-bright.png";
import heat from "./../../assets/Products/Kaoline/icons/heat.png";
import non from "./../../assets/Products/Kaoline/icons/Non-Abbrasive.png";
import visco from "./../../assets/Products/Kaoline/icons/natural-fine-particle.png";
import refractory from "./../../assets/Products/Kaoline/icons/Refractory.png";
import kaolin from "./../../assets/Products/Kaoline/Kaoline img/Kaoline.jpg";

const imageStyle = {
    margin: "0",

};
const Kaolin = () => {
    return (
        <>
            <div className="container">
                <div className="product-title">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 mt-5">
                            <img alt="images" src={kaolin} style={imageStyle} />
                        </div>
                        <div className="col-lg-6 col-sm-12 mt-4 mb-5">
                            <h1>Kaolin</h1>
                            <p>
                                Kaolin, also known as China clay, that is primarily composed of Kaolinite, which is
                                formed from the weathering of aluminum silicate minerals. It is named after Kao-ling
                                Mountain in China, where it was first mined. This clay is valued for its desirable
                                physical properties, including its whiteness, fine particle size, plasticity, and
                                non-abrasive nature. Kaolin has moderate plasticity; it can be shaped and molded without
                                excessive cracking. It is a versatile clay mineral that finds extensive use in the
                                ceramics industry, playing a crucial role in the production of porcelain, fine china,
                                and sanitaryware.
                            </p>
                            <div className="sio mt-4">
                                <p>
                                   Kaolinite is a silicate clay mineral with the chemical composition <br />
                                    <span className="head">
                                        <b>Al<sub>2</sub>Si<sub>2</sub>O<sub>5</sub>(OH)<sub>4</sub></b>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pProperty">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <h2>Physical Properties</h2>
                            <p>
                                Kaolin exhibits the following physical properties, which drive its application in across
                                various sectors.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row icons-container">
                    <div className="col-lg-2 col-md-12">
                        <img src={disperse} alt="" />
                        <h4>Easily Disperse In Water</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={bright} alt="" />
                        <h4>Very bright</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={heat} alt="" />
                        <h4>
                        Resistance To Heat & Electricity
                        </h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={non} alt="" />
                        <h4>Non Abbrasive</h4>
                    </div>

                    <div className="col-lg-2 col-md-12">
                        <img src={visco} alt="" />
                        <h4>Natural fine particles</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={refractory} alt="" />
                        <h4>Refractory</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 ">
                        <div className="product-application mt-5">
                            <h2>Application</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="porto-wrap-container">
                <Row>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper justify-center">
                            <div className="printing">
                                <Image src={printing} preview={false} />
                                <h3>PAPER INDUSTRY</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>PAPER INDUSTRY</b>
                                        <br />
                                        <p>
                                        Kaolin is used in the paper industry as a coating pigment, filler, and
                                        brightness enhancer, improving paper properties such as opacity, smoothness, and
                                        printability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                                <Image src={table} preview={false} />
                                <h3>TABLEWARE</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>TABLEWARE </b>
                                        <br />
                                        <p>
                                        Kaolin maintains its whiteness even when subjected to high temperature and
                                        process excellent fusion properties, making it useful in the production of
                                        tableware like dishes, mugs and more.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper ">
                                <Image src={rubber} preview={false} />
                                <h3>PLASTIC AND RUBBER </h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>PLASTIC AND RUBBER </b>
                                        <br />
                                        <p>
                                        Kaolin is preferable additive to rubber because of its “booklet” particle
                                        structure that enhances the stiffness and mechanical strength to the rubber
                                        product through high-quality stress transfer.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                                <Image src={powder} preview={false} />
                                <h3>PAINT AND COATING</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>PAINT AND COATING</b>
                                        <br />
                                        <p>
                                        Kaolin is used as a pigment extender and filler in the paints and coatings
                                        industry. It assists to achieve desired durability, rheological properties and
                                        provides a smooth finish.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default Kaolin;