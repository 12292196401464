import { Layout, Space} from "antd";
// import HeaderMenu from "./Header";
import ContentPage from "./Content";
import HeaderMenu from "./Header";
import Breadcrumb from "./Breadcrumb";
import FooterPage from "./Footer";
import "../../style/Footers.css";
import useResizeObserver from "../../common/useResizeObserver";
// import HeaderPageLayout from "../../Header";

const {Header, Footer, Content} = Layout;

const PageLayout = () => {
    const targetRef = useResizeObserver(100);
    
    return (
        <Layout className="layout" 
        ref={targetRef}>
            <div>
                <Header style={{background:"#ffff !important" , paddingInline:"0px",height:"auto"}}>
                    <HeaderMenu />
                </Header>
            </div>
            {/* <Breadcrumb /> */}
            <Content style={{background:"#ffff !important"}}>
                <ContentPage />
            </Content>
            <Footer style={{background:"#f5f5f5 !important"}} className="footer">
                <FooterPage  />
            </Footer>
        </Layout>
    );
};

export default PageLayout;
