import React from "react";
import "./../../style/AboutUs.css";
import mission from "../../assets/mission.png";
import vision from "../../assets/vision.png";
import a from "../../assets/About Our Company/Mission & Vission/Icons-40.png";
import b from "../../assets/About Our Company/Mission & Vission/Icons-41.png";
import c from "../../assets/About Our Company/Mission & Vission/Icons-42.png";
import d from "../../assets/About Our Company/Mission & Vission/Icons-43.png";
import e from "../../assets/About Our Company/Mission & Vission/Icons-44.png";
import f from "../../assets/About Our Company/Mission & Vission/Icons-45.png";

const MissionAndVision = () => {
    return (
        <>
        <div className="container mt-4">
            <div class="row">
                </div>
                    <div class="row mt-4">
                    <div class="col-md-6 mission-border"  >
                        <div class="row ">
                        <div className="col-md-2"></div>
                            <div class="col-md-2">
                                <div class="latest-blog-img ">
                                    <img src={mission} alt="" />
                                </div>
                            </div>
                            <div class="col-md-6 discover-heading">
                                <div class="lat-blog-desc" style={{padding: "20px"}}>
                                    <h3>MISSION</h3>
                                    <br />
                                    <p style={{textAlign:'justify'}} className="home-products">
                             Committed to deliver a wide range of high-quality products with a quick turnaround time through stringent quality control and a robust logistics network . Pursue best business practices with utmost integrity to make Dashmesh Minerals an exciting organization to work with , for vendors , channel partners , investors , and employees alike.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>

                    <div class="col-md-6" >
                        <div class="row">
                        <div className="col-md-2"></div>
                            <div class="col-md-2 ml-5">
                                <div class="latest-blog-img justify-content-end ">
                                    <img src={vision} alt="" />
                                </div>
                            </div>
                            <div class="col-md-6 discover-heading">
                                <div class="lat-blog-desc" style={{padding: "20px"}}>
                                    <h3>VISION</h3>
                                    <br />
                                    <p style={{textAlign:'justify'}} className="home-products">
                                    Gain worldwide recognition in the field of
ceramic raw minerals provider through the
right set of technology and processes.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-4 mt-4">
                <div class="row many  mb-0 discover-heading">
                <h3>Our Usp's</h3>
               
                             <div class="col-md-2 col-sm-12 ">
                        <img src={b} alt="" /> 
                        <h5>
                            Driving Innovation
                        </h5>
                    </div>
               
                    <div class="col-md-2 col-sm-12">
                        <img src={a} alt="" />
                        <h5>
                            Quality Products
                        </h5>
                    </div>

                    <div class="col-md-2 col-sm-12 ">
                        <img src={f} alt="" />
                        <h5>
                          Robust Infrastructure
                        </h5>
                    </div>

                    <div class="col-md-2 col-sm-12 ">
                        <img src={e} alt="" />
                        <h5>
                        Streamlined Supply Chain 
                        </h5>
                    </div>
       

                    <div class="col-md-2 col-sm-12 ">
                        <img src={d} alt="" /> 
                        <h5>
                          Logistics Network
                        </h5>
                    </div>

               
                </div>
            </div>
        </>
    );
};

export default MissionAndVision;
