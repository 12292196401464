import React from 'react'
import '../../style/Application.css'
import company from '../../assets/Applications/Heavy Engineering/Large.jpg'

const HeavyEngineering = () => {
    return (
        <>
            <div class="container">
                <div class="row application-detail">
                    <div class="col-md-6">
                        <img src={company} alt="" />
                    </div>
                    <div class="col-md-6">
                        <h3>HEAVY ENGINEERING</h3>
                        <h5>Our high-quality raw minerals cater to the variety of heavy engineering industrial needs, by elevating the
                            safety, strength, and stability of products. </h5>

                        <p>Kaolin, clay, and silica are crucial ingredients in porcelain insulator manufacturing. Kaolin provides plasticity and
                            translucency, clay acts as a binder for strength and stability, while silica offers high-temperature resistance and insulation.
                            The process involves mixing these materials with water, molded, and dried to shape the insulator. Firing at high temperature
                            fuses the particles, creating a dense and vitrified porcelain body. The exact proportions may vary, and manufactures may
                            have proprietary formulations to optimize electrical, mechanical, and thermal characteristics.</p>

                        <p>Dashmesh Minerals understands the challenges of this industry and has been coming up with raw minerals solutions through the following:</p>
                        <ul>
                            	<li>Stringent quality checks certify the high-quality production of raw minerals . </li>
                            	<li>Capable of processing large volumes of raw minerals through its multiple plants . </li>
                            	<li>Cutting-edge technology enables a quicker turnaround time . </li>
                            	<li>Widespread logistics network delivers excellence with seamless efficiency . </li>
                                </ul>
                    </div>
                </div>
            </div>
        </>
    )

}


export default HeavyEngineering;
