import React from 'react';
// import videos from '../../assets/simpolo video.mp4'
import thumbnailUrl from '../../assets/Crousal Photo-20230712T120219Z-001/Crousal Photo/1.jpg'
import videos from '../../assets/Video/01.mp4';

const VideoPlayer = () => {
  return (
    <div className="video-container">
      <video className='video' autoPlay muted controls loop src={videos} poster={thumbnailUrl}></video>
    </div>
  );
};

export default VideoPlayer;
