import React from 'react'
import '../../style/ApplicationDetail.css'
import company from '../../assets/Applications/Agriculture Industries/Large.jpg';
import gif from "../../assets/GIF for Infrastructure-20230712T123339Z-001/GIF for Infrastructure/nitesh gif animate.gif";

const Agriculture = () => {
    return (
        <>
            <div class="container">
                <div class="row application-detail">
                    <div class="col-md-6">
                        <img src={company} alt="" />
                    </div>
                    <div class="col-md-6">
                        <h3>AGRICULTURE INDUSTRIES </h3>
                        <h5>Reliable and high-quality Bentonite for agriculture industry to enhance the inherent properties of their product. </h5>
                        <p>Bentonite is valuable in the fertilizer industry for several purposes. It serves as a binder, granulation agent,
                            improving the strength of granular fertilizers. Its water-absorbing properties aid in moisture retention in arid soils,
                            while high cation exchange capacity enhances nutrient-holding capacity and minimizes leaching. It acts as an anti-caking agent,
                            preventing clumping in fertilizers, and can be used as a coating material for slow-release fertilizers. Additionally, bentonite
                            helps adjust soil pH and improves overall fertilizer efficiency, benefiting crop productivity.</p>

                        <p>Dashmesh Minerals understands the challenges of this industry and has been coming up with raw minerals solutions through the following:</p>
                        <ul>
                            	<li>Innovation-driven production approach facilitated by highly competent team  . </li>
                            	<li>Optimized supply chain operations to accommodate varying demands for raw minerals. </li>
                            	<li>Quality Assurance at every stage through out the entire process . </li>
                            	<li>Able to manage vast volumes of raw minerals through cutting-edge processing plants . </li>                         </ul>
                        
                    </div>
                </div>
            </div>
        </>
    )

}


export default Agriculture;
