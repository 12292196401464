import React from "react";
// import videos from "../../assets/simpolo video.mp4";
import emailjs from "emailjs-com";
import crousel1 from "../../assets/Crousal Photo-20230712T120219Z-001/Crousal Photo/Required Size/1.JPG";
import crousel2 from "../../assets/Crousal Photo-20230712T120219Z-001/Crousal Photo/Required Size/2.JPG";
import crousel3 from "../../assets/Crousal Photo-20230712T120219Z-001/Crousal Photo/Required Size/3.JPG";
import crousel4 from "../../assets/Crousal Photo-20230712T120219Z-001/Crousal Photo/Required Size/4.JPG";
import crousel55 from "../../assets/Crousal Photo-20230712T120219Z-001/Crousal Photo/Required Size/5.JPG";
import sketch from "../../assets/Sketch/7a.jpg"
import clay from "./../../assets/Products/Ball clay/Ball Clay img/Ball Clay.jpg";
import bento from "./../../assets/Products/Bentonite/Bentonite img/Bentonite.jpg";
import kaolin from "./../../assets/Products/Kaoline/Kaoline img/Kaoline.jpg";
import silica from "./../../assets/Products/Silica/Silica Sand img/Silica Sand.jpg";
import gif from "../../assets/GIF for Infrastructure-20230712T123339Z-001/GIF for Infrastructure/manmeet dashmesh gif.gif";
import mining from "../../assets/Collage Image/collage final image.jpg";
import bullet1 from '../../assets/Home/Bullet Images/1.png';
import bullet2 from '../../assets/Home/Bullet Images/2.png';
import bullet3 from '../../assets/Home/Bullet Images/3b.png';
import bullet4 from '../../assets/Home/Bullet Images/4.png';
import bullet5 from '../../assets/Home/Bullet Images/5.png';
import bullet6 from '../../assets/Home/Bullet Images/6.png';

import "../../style/Home.css";
import VideoPlayer from "../Common/VideoPlayer";
import { Button, Carousel, Image, Col, Row } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Home = () => {
    const prevArrow = (
        <Button className="carousel-arrow carousel-prev-arrow">
            <ArrowLeftOutlined />
        </Button>
    );

    const nextArrow = (
        <Button className="carousel-arrow carousel-next-arrow">
            <ArrowRightOutlined />
        </Button>

        
    );

    const onFinish = (values) => {
        console.log(values);
        // Replace these values with your EmailJS credentials and template ID
        const serviceId = "service_ef2h6hj";
        const templateId = "template_yzgo71k";
        const userId = "CbJmFbX5Rzn90GTkA";

        emailjs
        .send(serviceId, templateId, values, userId)
        .then((response) => {
            console.log("Email sent successfully!", response);
        })
        .catch((error) => {
            console.error("Error sending email:", error);
        });
    };

    

    return (
        <>
            <div className="container-fluid">
                <div className="col-md-12">
                    <div className="dashmesh">
                        <VideoPlayer />
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row discover-heading">
                    <div class="col-md-12">
                        <h3>DISCOVER US</h3>
                    </div>
                </div>
                <div class="row discover">
                    <div class="col-lg-5 col-sm-12 ">
                        <h3>DASMESH MINERALS AT A GLANCE</h3>
                        <p>
                            Dashmesh Minerals has carved its niche as a consistently growing organisation with
                            unparalleled environment sustainable manufacturing and mining. Our integrated business
                            approach elevates the service experience for clients, who ensured by quick, seamless, and
                            effective support.
                        </p>
                        <p className="mb-4">
                            Be it prominent materials like Silica or Ball Clay or specific finds of Ochre, Dashmesh
                            Minerals can cater to any type of requirements. With its robust infrastructure, R & D setup,
                            hi-tech processes, widespread logistics network and competent workforce, Dashmesh Minerals
                            is serving customers with highly competitive turnaround times and become one-stop integrated
                            destination for quality minerals.
                        </p>
                        <span className="explore">
                            <Link to="/company-profile">EXPLORE</Link>
                        </span>
                    </div>
                    <div class="col-lg-7 col-sm-12">
                        <img src={sketch} alt="" />
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="discover-heading">
                    <div class="col-lg-12">
                        <h3>Our Products</h3>
                    </div>
                </div>
                <div class="row home-products ">
                    <div class="col-md-3 col-sm-12 mx-auto d-block">
                        <img src={clay} alt="" />
                        <span>
                            <p>ball clay</p>
                        </span>
                    </div>
                    <div class="col-md-3  col-sm-12 mx-auto d-block">
                        <img src={bento} alt="" />
                        <span>
                            <p>bentonite</p>
                        </span>
                    </div>
                    <div class="col-md-3 col-sm-12 mx-auto d-block">
                        <img src={kaolin} alt="" />
                        <span>
                            <p>kaolin</p>
                        </span>
                    </div>
                    <div class="col-md-3 col-sm-12 mx-auto d-block">
                        <img src={silica} alt="" />
                        <span>
                            <p>silica sand</p>
                        </span>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row discover-heading">
                    <div class="col-md-12">
                        <h3>Our Infrastructure</h3>
                    </div>
                </div>

                {/* Main content */}
                <div className="row infrastructure">
                    <Carousel
                        dotPosition="bottom"
                        prevArrow={prevArrow}
                        nextArrow={nextArrow}
                        autoplay
                        autoplaySpeed={2000}
                        className="carousel"
                    >
                        <div className="dasmeshCraousel">
                            <img src={crousel1} alt="Image 1" />
                        </div>
                        <div className="dasmeshCraousel">
                            <img src={crousel2} alt="Image 2" />
                        </div>
                        <div className="dasmeshCraousel">
                            <img src={crousel3} alt="Image 3" />
                        </div>
                        <div className="dasmeshCraousel">
                            <img src={crousel4} alt="Image 4" />
                        </div>
                        <div className="dasmeshCraousel">
                            <img src={crousel55} alt="Image 5" />
                        </div>
                    </Carousel>
                    <div className="row content">
                    <div className="col-md-1 col-sm-4">

                    </div>
                        <div className="col-md-3 col-sm-8">
                            <div class="discover">
                                <h2>
                                    DRIVEN BY MINERALS , <br /> POWERED BY <br /> VISION
                                </h2>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-12">
                            <div className="driven-content justify-content-center">
                                <p>
                                    By utilizing advance facilities for mining, cutting-edge processing plants and
                                    efficient logistics, we are able to deliver high-quality minerals. Our
                                    state-of-the-art infrastructure ensures streamline operations and timely delivery.
                                    At every stage, the in-house dedicated laboratory conducts thorough testing to
                                    uphold strict quality control measures. Safety and environmental considerations take
                                    precedence as we adhere to regulations and promote sustainable practices. Trust our
                                    robust infrastructure to deliver exceptional products and services, driving growth
                                    and positive impact.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row discover-heading">
                    <div class="col-md-12">
                        <h3>How we work</h3>
                    </div>
                </div>
                <div class="row collage">
                    <div class="col-md-12">
                        <img src={mining} alt="" />
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row discover-heading">
                    <div class="col-md-12">
                        <h3>Our strengths</h3>
                    </div>
                </div>
                <div class="row strengths">
                    <div class="col-md-6">
                        <h3>WHY CHOOSE US</h3>
                        <p>
                            Our partnership with our clients is based on mutual trust and we do what is best for our
                            clients due to following:
                        </p>
                        <h5>
                            <span>
                                <img src={bullet1} alt="" srcset="" className="bullet-image mb-1" />
                            </span>{" "}
                            Highly Competent team
                            <br />
                            <span>
                                <img src={bullet2} alt="" srcset="" className="bullet-image mb-1" />
                            </span>{" "}
                            Robust Infrastructure
                            <br />
                            <span>
                                <img src={bullet3} alt="" srcset="" className="bullet-image mb-1" />
                            </span>{" "}
                            Hi-Tech Processing Plant
                            <br />
                            <span>
                                <img src={bullet4} alt="" srcset="" className="bullet-image mb-1" />
                            </span>{" "}
                            Widespread logistic network
                            <br />
                            <span>
                                <img src={bullet5} alt="" srcset="" className="bullet-image mb-1" />
                            </span>{" "}
                            Streamlined supply chain
                            <br />
                            <span>
                                <img src={bullet6} alt="" srcset="" className="bullet-image mb-1" />
                            </span>{" "}
                            Quality Assured
                        </h5>
                    </div>
                    <div class="col-md-6">
                        <div className="gif">
                            <img src={gif} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="container">
                <div class="subscribe">
                    <div class="row">
                        <div class="col-md-12">
                            <h3>
                                If you want, we'll keep you updated on <br />
                                the latest news about products
                            </h3>
                            <input type="" onFinish={onFinish} placeholder=" Your email Address" /> <br />
                            <button htmlType="submit">SUBSCRIBE</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default Home;
