import React from "react";
import "../../style/AboutUs.css";
// import company from "../../assets/compny.jpg";
import profile from "../../assets/About Our Company/MYK02697_01 (27).jpg";

const CompanyProfile = () => {
    return (
        <div class="container">
            <div class="row cp">
                <div class="col-md-6 mb-4">
                    <img src={profile} alt="" />
                </div>
                <div class="col-md-6 mb-4 mt-5">
                    <h3>ABOUT OUR COMPANY</h3>
                    <p>
                        Dashmesh Minerals has carved its niche as a consistently growing organisation with unparalleled
                        environment sustainable manufacturing and mining. Our integrated business approach elevates the
                        service experience for clients, who ensured by quick, seamless, and effective support.{" "}
                    </p>
                    <p>
                        Be it prominent materials like Silica or Ball Clay or specific finds of Ochre, Dashmesh Minerals
                        can cater to any type of requirements. With its robust infrastructure, R & D setup, hi-tech
                        processes, widespread logistics network and competent workforce, Dashmesh Minerals is serving
                        customers with highly competitive turnaround times and become one-stop integrated destination
                        for quality minerals.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CompanyProfile;
