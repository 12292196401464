import flogo from "./../../assets/Footer Logo and Content-20230712T123338Z-001/Footer Logo and Content/black-logo.png";
import qrcode from "./../../assets/qr-code.png";
// import {handleDownload} from "../../common/common";
import pdfFile from '../../assets/Layout/Company Portfolio.pdf';
import "../../style/Footers.css";
import {BiLogoWhatsapp} from "react-icons/bi";
import {Link} from "react-router-dom";

const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfFile;
    link.download = 'Dashmesh-minerals.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

const FooterPage = () => {
    return (
        <>
            <div className="container-fluid" style={{paddingTop: "40px", paddingLeft: " 50px", paddingRight: "50px"}}>
                <div className="item">
                    <div className="row" style={{marginBottom: "40px"}}>
                        <div className="col-lg-8 col-sm-12">
                            <div className="logo ">
                                <img src={flogo} alt="" />
                            </div>
                            <div className="address">
                                <h4>Address: C-129 Kanta Khaturia Colony, Bikaner - 334001, Rajasthan, INDIA</h4>
                                <h4>
                                    Contact Details :{" "}
                                    <span>
                                        {" "}
                                        <a href={`tel: 9649520959`}>+91 9649520959</a> | 
                                        <a href={`tel: 7014117310`}>+91 7014117310</a> | 
                                        <span></span>
                                        <BiLogoWhatsapp /> <a href={`tel:+91 7014117310`}>+91 7014117310</a>
                                    </span>
                                </h4>
                                <h4>
                                    Email Id :{" "}
                                    <span>
                                        <a href={`mailto:info@dashmeshminerals.com`}>info@dashmeshminerals.com</a>
                                    </span>{" "}
                                </h4>
                            </div>
                            <div className="qr-code">
                                <img src={qrcode} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            {/* <div className="simpolo"> */}
                            {/* <div className="quicklink">
                                    <h4>QUICK LINKS </h4>
                                </div>
                                <div className="footer-products mb-4">
                                    <Link to="about-us/company-profile" className="link">
                                        {" "}
                                        COMPANY PROFILE
                                    </Link>{" "}
                                    |{" "}
                                    <a className="link" onClick={() => handleDownload()}>
                                        DOWNLOADS
                                    </a>{" "}
                                    |{" "}
                                    <Link to="/enquiry" className="link">
                                        ENQUIRY
                                    </Link>{" "}
                                    |{" "}
                                    <Link to="/applications" className="link">
                                        APPLICATIONS
                                    </Link>{" "}
                                </div> */}
                            <div className="footer-products">
                                <h4>QUICK LINKS</h4>
                                <div className="tile">
                                    {" "}
                                    <Link to="company-profile" className="link">
                                        COMPANY PROFILE
                                    </Link>{" "}
                                    |{" "}
                                    <a className="link" onClick={() => handleDownload()}>
                                        DOWNLOADS
                                    </a>{" "}
                                    |{" "}
                                    <Link to="enquiry" className="link">
                                        {" "}
                                        ENQUIRY
                                    </Link>{" "}
                                    |{" "}
                                    <Link to="/applications" className="link">
                                        APPLICATIONS
                                    </Link>{" "}
                                </div>
                            </div>
                            <div className="footer-products">
                                <h4>PRODUCTS</h4>
                                <div className="tile">
                                    {" "}
                                    <Link to="silica-sand" className="link">
                                        SILICA SAND
                                    </Link>{" "}
                                    |{" "}
                                    <Link to="ball-clay" className="link">
                                        {" "}
                                        BALL CLAY
                                    </Link>{" "}
                                    |{" "}
                                    <Link to="/bentonite" className="link">
                                        BENTONITE
                                    </Link>{" "}
                                    |{" "}
                                    <Link to="/kaolin" className="link">
                                        KAOLIN
                                    </Link>{" "}
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FooterPage;
