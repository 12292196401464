import pdfFile from '../assets/Catelogue.pdf';

const products = {
    silicaSand: {
        name: "Silica Sand",
        urlName: "silica-sand",
        description: "This is description",
        compositionFormula: "Silica Sand’s chemical composition is SiO2",
    },
    ballClay: {
        name: "Silica Sand",
        urlName: "silica-sand",
        description: "This is description",
        compositionFormula: "Silica Sand’s chemical composition is SiO2",
    },
    silicaSand: {
        name: "Silica Sand",
        urlName: "silica-sand",
        description: "This is description",
        compositionFormula: "Silica Sand’s chemical composition is SiO2",
    },
    silicaSand: {
        name: "Silica Sand",
        urlName: "silica-sand",
        description: "This is description",
        compositionFormula: "Silica Sand’s chemical composition is SiO2",
    },
};

export const enquiryOptions = {
      selectProducts : [
        { item: "Ball Clay" } ,
        { item: "Bentonite" } ,
        { item: "Kaolin" } ,
        { item: "Silica Sand" } 
        ],
      enquiryType:[
        {id: "Enquiry Type"},
        {id: "Product"},
        {id: "Dealer"},
        {id: "Supplier"},
        {id: "Project"},
      ],
};

export const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfFile;
    link.download = '.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  export const CommonBreadCrumb =  {
    'director-message'  : 'Director Message' , 
    'company-profile' : 'Company Profile',
    'mission-vision' : 'Mission & Vision',
    'r-d' : 'R & D',
    'silica-sand' : 'Silica Sand',
    'ball-clay' : 'Ball Clay',
    'bentonite' : 'Bentonite',
    'kaolin' : 'Kaolin',
    'enquiry' : 'Enquiry',
    'feedback' : 'Feedback',
    'contact-Details' : 'Contact details',
    'applications' : 'Applications',
    'refractories' : 'Refractories',
    'sanitary-ware' : 'Sanitary Ware',
    'tiles': 'Tiles',
    'agriculture' : 'Agriculture',
    'heavy-engineering' : 'Heavy Engineering',
    'industrial-abrasives' : 'Industrial Abrasives',
  }
