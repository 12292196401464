import React from 'react'
import chairmen from '../../assets/Directors/Director 1.jpg';
import chairWomen from '../../assets/Directors/Director 2.JPG';
import './../../style/AboutUs.css'
const DirectorsMessage = () => {
    return (
        <>
            <div className="container">
                <div className="vision">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="chairman">
                                <img src={chairmen} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="para">
                                <h3>NITESH BINDAL</h3>
                                <p className="directors">Director</p>
                                <p>B.Tech. Ceramics</p>
                                <h6>
                                    Actively associated with Design Innovations, Product, <br />
                                    R&D, Channel Outreach and Sales. Equal focus on <br />
                                    Marketing Strategy, Communications, and overall profitability.
                                </h6>
                            </div>
                        </div>
                        {/* <div className="col-lg-5 col-sm-12">
                            <div className="chairman">
                                <img src={chairmen} alt="" />
                            </div>
                            <div class="col-md-1" style={{maxWidth: "3%"}}></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="para">
                                <h3>NITESH BINDAL</h3>
                                <p className="directors">Director</p>
                                <p>B.Tech. Ceramics</p>
                                <h6>
                                    Actively associated with Design Innovations, Product, <br />
                                    R&D, Channel Outreach and Sales. Equal focus on <br />
                                    Marketing Strategy, Communications, and overall profitability.
                                </h6>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="vision">
                    <div className="row justify-content-center">
                        {/* <div className="col-lg-6">
                            <div className="chairman">
                                <img src={chairWomen} alt="" />
                            </div>
                        </div>
                        <div class="col-md-1" style={{maxWidth: "3%"}}></div>
                        <div className="col-lg-5 col-sm-12">
                            <div className="para">
                                <h3>SHARAN JEET KAUR</h3>
                                <p className="directors">Director</p>
                                <p>B.Tech. Civil</p>
                                <h6>
                                    Deep field knowledge and experience, Hands on involvement <br />
                                    Initiation and execution of strategy, Drives High Governance <br />
                                    Standards.
                                </h6>
                            </div>
                        </div> */}

                        <div className="col-lg-6">
                        <div className="chairman">
                                <img src={chairWomen} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="para">
                                <h3>SHARAN JEET KAUR</h3>
                                <p className="directors">Director</p>
                                <p>B.Tech. Civil</p>
                                <h6>
                                    Deep field knowledge and experience, Hands on involvement <br />
                                    Initiation and execution of strategy, Drives High Governance <br />
                                    Standards.
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* second  */}

            {/* <div className="container">
                <div className="vision">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="chairman">
                                <img src={chairman} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="para">
                                <h3>
                                    MANMEET SINGH
                                </h3>
                                <p className="first">
                                    Director
                                </p>
                                <p>
                                    B.Tech. Civil
                                </p>
                                <h6>
                                    Deep field knowledge and experience, Hands on involvement  <br />
                                    Initiation and execution of strategy, Drives High Governance   <br />
                                    Standards.

                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default DirectorsMessage;