import React from "react";
import { Link , useLocation} from "react-router-dom";
import { Breadcrumb } from "antd";
import { CommonBreadCrumb } from "../../common/common";

const CustomBreadcrumb  = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((path) => path);
    if (pathnames.length === 0 || (pathnames.length >0 && pathnames[0] === 'Home')) {
      // Hide breadcrumb on the home page
      return null;
    }
    return (
      <div className="container-fluid" style={{marginTop:"2px",marginBottom:'20px', maxWidth:':95%'}}>
        <div style={{background: "#f5f5f5", padding: "30px 65px"}}>
        <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        {pathnames.map((path, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          console.log("path",path, isLast)
          return (
            <Breadcrumb.Item key={path}>
              <span>{CommonBreadCrumb[path]}</span>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      </div>
    </div>
    );
  }

  export default CustomBreadcrumb ;
