import React from "react";
import { Image, Row, Col, Tooltip, Card } from "antd";
import "./../../style/product.css";

import cosmetic from "./../../assets/Products/Bentonite/Cosmetic/2.jpg";
import wine from "./../../assets/Products/Bentonite/Wine Making/barrique.jpg";

import bentonite from "./../../assets/Products/Bentonite/icons/Bentonite.png";
import Agriculture from "./../../assets/Products/Bentonite/Agriculture/Agriculture.jpg";
import molasses from "./../../assets/Products/Bentonite/Molasses/image.jpg";
// import high from "./../../assets/Products/Bentonite/icons/High.png";
import plasticity from "./../../assets/Products/Bentonite/icons/Plasticity.png";
import thixotrophy from "./../../assets/Products/Bentonite/icons/Thixotrophy.png";
// import visco from "./../../assets/Products/Bentonite/icons/viscocity-1.png";
import viscocity from "./../../assets/Products/Bentonite/icons/viscocity.png";
import Water from "./../../assets/Products/Bentonite/icons/Water-Absorption.png";
import bento from "./../../assets/Products/Bentonite/Bentonite img/Bentonite.jpg";

const imageStyle = {
    margin: "0",

};
const Bentonite = () => {
    return (
        <>
            <div className="container">
                <div className="product-title">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 mt-5">
                            <img alt="images" src={bento} style={imageStyle} />
                        </div>
                        <div className="col-lg-6 col-sm-12 mt-4 mb-5">
                            <h1>Bentonite Clay</h1>
                            <p>
                                Bentonite Clay is derived from volcanic ash, which is predominantly composed of
                                montmorillonite, along with other minerals such as quartz, feldspar, calcite, and
                                gypsum. It has a very fine and soft texture, and when mixed with water, it forms a
                                paste. Bentonite exhibits good plasticity, strong colloidal properties, thixotropy,
                                viscosity etc. Hence this paste is widely used. Its versatility, coupled with its
                                ability to absorb water and form gels, makes it a valuable material in various
                                industries and sectors.
                            </p>
                            <div className="sio mt-4">
                                <p>
                                    The chemial formula of bentonite is composed of montnorollonite , an alluminium phyllosilicate clay : <br />
                                    <span className="head">
                                        <b>su(Na,Ca) <sub> 0.33</sub> (Al,Mg)<sub>2</sub>Si<sub>4</sub> O<sub>10</sub> (OH2)nH<sub>2</sub>O</b>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pProperty">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <h2>Physical Properties</h2>
                            <p>
                                Bentonite has the following physical properties, which drive its application in across
                                various sectors.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row icons-container">
                    <div className="col-lg-2 col-md-12">
                        <img src={bentonite} alt="" />
                        <h4>Bentonite</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={plasticity} alt="" />
                        <h4>High Plasticity</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={thixotrophy} alt="" />
                        <h4>Thixotrophy</h4>
                    </div>

                    <div className="col-lg-2 col-md-12">
                        <img src={viscocity} alt="" />
                        <h4>Viscocity</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={Water} alt="" />
                        <h4>Water Absorption</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 ">
                        <div className="product-application mt-5">
                            <h2>Application</h2>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="porto-wrap-container">
                <Row>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper justify-center">
                                <Image
                                    src={Agriculture}
                                    preview={false}
                                />
                                <h3>PELLETIZING</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>PELLETIZING</b>
                                        <br />
                                        <p>
                                        Bentonite is used in pelletizing, especially in iron ore pellet production, to enhance
                                         agglomeration, strength, and durability of the pellets through binding, water absorption, 
                                         and plasticity.
                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                    
                       
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper ">
                                <Image src={cosmetic} preview={false} />
                                <h3>COSMETICS</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>COSMETICS</b>
                                        <br />
                                        <p>
                                        Bentonite has the ability to absorb excess oil and dirt form the skin, making it
                                        a key ingredient in skin care products like cleansers and facial masks.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </Col>
                    <Col md={6}>
                    <div className="image-container">
                            <div className="image-wrapper">
                                <Image src={molasses} preview={false} />
                                <h3>MOLASSES</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>MOLASSES</b>
                                        <br />
                                        <p>
                                        Bentonite is used in molasses production as a clarifying agent, removing impurities and
                                         improving the appearance and quality of the final product.
                                         </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    
                   
                    </Col>
                    <Col md={6}>
                    <div className="image-container">
                            <div className="image-wrapper">
                                <Image src={wine} preview={false} />
                                <h3>WINEMAKING</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>WINEMAKING</b>
                                        <br />
                                        <p>
                                        Bentonite is used in winemaking for clarification, protein stabilization, pH
                                        adjustment, preventing off-flavours, and setting solids during fermentation and
                                        aging processes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default Bentonite;