import {Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import CompanyProfile from "../AboutUs/CompanyProfile";
import DirectorsMessage from "../AboutUs/DirectorsMessage";
import MissionAndVision from "../AboutUs/MissionAndVision";
import RND from "../AboutUs/R&D";
import Applications from "../Applications/Applications";
import BallClay from "../Products/BallClay";
import SilicaSand from "../Products/SilicaSand";
import Downloads from "../Downloads/Downloads";
import Enquiry from "../ContactUs/Enquiry";
import Feedback from "../ContactUs/Feedback";
import Bentonite from './../Products/Bentonite';
import CustomBreadcrumb  from "./Breadcrumb";
import Kaolin from './../Products/Kaolin';
import Tiles from "../Applications/Tiles";
import Refractories from "../Applications/Refractories";
import SanitaryWare from "../Applications/SanitaryWare";
import Agriculture from  "./../Applications/Agriculture";
import HeavyEngineering from './../Applications/HeavyEngineering';
import IndustrialAbrasives from './../Applications/IndustrialAbrasives';
import ContactDetails from "../ContactUs/ContactDetails";

const ContentPage = () => {
    return (
      <>
      <CustomBreadcrumb  />
        <Routes>
        <Route index element={<Home/>}/>
          <Route  path="Home" element={<Home />} />
          <Route path="company-profile" element={<CompanyProfile />} />
          <Route path="director-message" element={<DirectorsMessage />} />
          <Route path="mission-vision" element={<MissionAndVision />} />
          <Route path="applications" element={<Applications />} />
          <Route path="ball-clay" element={<BallClay />} />
          <Route path="silica-sand" element={<SilicaSand />} />
          <Route path="" element={<Downloads />} />
          <Route path="enquiry" element={<Enquiry />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="contact-Details" element={<ContactDetails/>} />
          <Route path="bentonite" element={<Bentonite />} />
          <Route path="kaolin" element={<Kaolin />} />
          <Route path="r-d" element={<RND />} />
          <Route path="kaolin" element={<Kaolin />} /> 
          <Route path="applications/refractories" element={<Refractories />} /> 
          <Route path="applications/sanitary-ware" element={<SanitaryWare />} /> 
          <Route path="applications/tiles" element={<Tiles />} />
          <Route path="applications/agriculture" element={<Agriculture />} />
          <Route path="applications/heavy-engineering" element={<HeavyEngineering />} />
          <Route path="applications/industrial-abrasives" element={<IndustrialAbrasives />} />
        
          
      </Routes> 
    </>
    )
}

export default ContentPage