import React from "react";
import {Image, Row, Col, Tooltip, Card} from "antd";
import "./../../style/product.css";
import industry from "./../../assets/Products/Silica/Industrial Abrasives/abrasives.jpg";
import electric from "./../../assets/Products/Silica/Foundary/electric.jpg";
import glass from "./../../assets/Products/Silica/Glass/glassmelting.jpg";
import ceramics from "./../../assets/Products/Silica/Ceramics and Refractory/Clei.jpeg";
import chemical from "./../../assets/Products/Silica/Icons/Chemical-Inhet.png";
import extremly from "./../../assets/Products/Silica/Icons/Extremly-hard.png";
import melting from "./../../assets/Products/Silica/Icons/highmeltingpoint.png";
import insoluble from "./../../assets/Products/Silica/Icons/Insoluble-1.png";
// import thermal from './../../assets/Products/Silica/Icons/Thermal-resistance-1.png';
import resistance from "./../../assets/Products/Silica/Icons/thermal-resistance.png";
import main from "./../../assets/Products/Silica/Silica Sand img/Silica Sand.jpg";

const imageStyle = {
    margin: "0",
};
const SilicaSand = () => {
    return (
        <>
            <div className="container">
                <div className="product-title">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 mt-5">
                            <img alt="images" src={main} style={imageStyle} />
                        </div>
                        <div className="col-lg-6 col-sm-12 mt-4 mb-4">
                            <h1>Silica Sand</h1>
                            <p>
                                Silica sand primarily consists of silicon dioxide (SiO2), typically in the form of
                                quartz crystals. Silica sand is made up of tiny granules of quartz, which is a hard and
                                crystalline mineral. Silica sand is a versatile industrial mineral that plays a crucial
                                role in various sectors. Its abundance, unique properties, and ability to withstand high
                                temperatures make it a valuable resource in glass manufacturing, construction, paints,
                                ceramic, industrial abrasives and many other such industries. As industries continue to
                                evolve, silica sand will remain an essential component in driving innovation and
                                progress.
                            </p>
                            <div className="sio mt-4">
                                <p>
                                    Silica Sand’s chemical composition is <br />
                                    <span className="head">
                                        <b>SiO<sub>2</sub></b>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pProperty">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <h2>Physical Properties</h2>
                            <p>
                                Silica sand exhibits the following physical properties, which drive its application in
                                across various sectors.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row icons-container">
                    <div className="col-lg-2 col-md-12">
                        <img src={chemical} alt="" />
                        <h4>Chemically Inert</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={extremly} alt="" />
                        <h4>Extremly Hard</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={melting} alt="" />
                        <h4>
                            High melting and <br /> boiling point
                        </h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={insoluble} alt="" />
                        <h4>Insoluble</h4>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <img src={resistance} alt="" />
                        <h4>Thermal Stable</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="product-application mt-5">
                            <h2>Application</h2>
                            {/* <p>
                                Due to its abundance and critical properties, Feldspars are an integral constituent in the
                                manufacturing of a lot of items in our daily lives:
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="porto-wrap-container mb-2">
                <Row>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper justify-center">
                                <Image src={industry} preview={false} />
                                <h3>INDUSTRIAL ABRASIVE</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>INDUSTRIAL ABRASIVE</b>
                                        <br />
                                        <p>
                                        Silica sand is widely used as a mineral abrasive for industrial blasting due to
                                        its round and angular varieties, to smoothen a rough surface.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                                <Image src={electric} preview={false} />
                                <h3>FOUNDRY </h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>FOUNDRY </b>
                                        <br />
                                        <p>
                                        Silica sand has high melting point, thermal stability, and permeability, which
                                        make it an indispensable ingredient in the casting process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                                <Image src={glass} preview={false} />
                                <h3>GLASS INDUSTRY </h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>GLASS INDUSTRY </b>
                                        <br />
                                        <p>
                                        Silica is essential in the glass industry, enabling the production of diverse
                                        glass types with desired properties such as transparency, strength, thermal
                                        resistance, and optical clarity.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="image-container">
                            <div className="image-wrapper">
                                <Image src={ceramics} preview={false} />
                                <h3>CERAMICS & REFRACTORIES</h3>
                                <div className="image-overlay">
                                    <div className="image-text hover-text">
                                        <b>CERAMICS & REFRACTORIES</b>
                                        <br />
                                        <p>
                                        Silica is extensively used in the production and glazing of various ceramics, as
                                        well as in the manufacturing of essential components for refractories.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default SilicaSand;
