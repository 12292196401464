import React from 'react'
import '../../style/ApplicationDetail.css'
import refractories from '../../assets/Applications/Refractories/Large.jpg'

const Refractories = () => {
    return (
        <div class="container">
            <div class="row application-detail">
                <div class="col-md-6 col-sm-12">
                    <img src={refractories} alt="" />
                </div>
                <div class="col-md-6 col-sm-12">
                    <h3>REFRACTORIES</h3>
                    <h5>We cater to the global market by offering a diverse selection of high-quality raw materials used in the
                        production of sustainable and top quality refractory products. </h5>
                    <p>Clay and silica are vital to the refractory industry. Clay acts as a binder, offering plasticity and enhancing strength in
                        refractory materials. Silica has a high melting point, low thermal expansion, and chemical resistance, ensuring refractories
                        withstand extreme heat and corrosive environments, making it preferred material in glass production and metal casting. Its
                        insulating properties reduce heat transfer. Together, clay and silica are instrumental in manufacturing refractories used in
                        furnaces, kiln, and reactors, providing durability and thermal protection in high-temperature industrial processes.</p>
                    <p>Dashmesh Minerals understands the challenges of this industry and has been coming up with raw minerals solutions through the following:</p>
                     <ul>
                        <li>	Detailed understating of industry requirement . </li>
                        <li>	Strong production line capable of handling surges in demand . </li>
                        <li>	Ensuring the provision of sustainable products through high-quality supply . </li>
                        <li>	Globally established logistics network catering to supply need across all regions . </li> 
                        </ul>              
                </div>
            </div>
        </div>
    )

}


export default Refractories;
