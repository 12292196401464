import React from "react";
import addressIcon from "../../assets/Contact-details/address-icon (1).png";
import messageIcon from "../../assets/Contact-details/mail-icon.png";
import phoneIcon from "../../assets/Contact-details/phone-icon.png";
import {BiLogoWhatsapp} from "react-icons/bi";

import "../../style/ContactUs.css";
const ContactDetails = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="contact mb-sm-20 mt-4">
                                    <ul>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={addressIcon} alt="" width={30} />
                                            </div>
                                            <div className="contact-content">
                                                <h3>Visit Office</h3>
                                                <h4>Dashmesh Ceramics </h4>
                                                <p>C-129 Kanta Khaturia Colony, Bikaner - 334001, Rajasthan, INDIA</p>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="contact-icon">
                                                <img src={messageIcon} alt="" width={30} />
                                            </div>
                                            <div className="contact-content">
                                                <h3>Email</h3>
                                                <p>
                                                    {" "}
                                                    <span>
                                                        <a href={`mailto:info@dashmeshminerals.com`}>
                                                            info@dashmeshminerals.com
                                                        </a>
                                                    </span>{" "}
                                                </p>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="contact-icon">
                                                <img src={phoneIcon} alt="" width={30} />
                                            </div>
                                            <div className="contact-content">
                                                <h3>Call us</h3>

                                                <p>
                                                <a href={`tel: +91 9649520959`}>+91 9649520959</a>  <br/>
                                                <a href={`tel: +91 7014117310`}>+91 7014117310</a> | 
                                                    <br />
                                                    <BiLogoWhatsapp /> <a href={`tel:+91 7014117310`}>+91 7014117310</a>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-6 col-sm-12 ">
                                <div className="map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d720.9992276947487!2d73.35224030124087!3d28.008318112803156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393fe75c7c388779%3A0x599f97e6088c61e0!2sC130%2C%20Shivbari%2C%20Bikaner%2C%20Rajasthan%20334003!5e0!3m2!1sen!2sin!4v1690044357134!5m2!1sen!2sin"
                                        style={{
                                            border: "0",
                                            allowfullscreen: "",
                                            loading: "lazy",
                                        }}
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactDetails;
