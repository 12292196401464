import React from 'react'
import '../../style/ApplicationDetail.css'
import industrialAbrasive from '../../assets/Applications/Industrial Abrasives/Large.jpg'

const IndustrialAbrasive = () => {
    return (
        <>
            <div class="container">
                <div class="row application-detail">
                    <div class="col-md-6">
                        <img src={industrialAbrasive} alt="" />
                    </div>
                    <div class="col-md-6">
                        <h3>INDUSTRIAL ABRASIVES</h3>
                        <h5>Reliable and high-quality minerals for industries engaged in manufacturing top quality abrasives. </h5>
                        <p>Silica is widely used as an abrasive in various industrial applications. It is commonly employed in sandblasting
                            to remove paint, rust and contaminants from surfaces, due to its round and angular varieties, to smoothen a rough
                            surface. Silica-based abrasives are utilized in grinding, polishing, cutting and sawing operations to shape, smooth,
                            and cut materials such as metal, concrete, and stone. They are also effective for surface preparation before
                            coating or painting, as well as deburring and deflashing to achieve precise finishes.</p>

                        <p>Dashmesh Minerals understands the challenges of this industry and has been coming up with raw minerals solutions through the following:</p>
                       <ul>
                            <li>	Consistent and reliable supply of raw minerals  .</li>
                            <li>	Competent R&D team is actively engaged in production innovation .</li>
                            <li>	Robust operational and quality control processes established .</li>
                            <li>	Streamlined logistics network ensures the precise and timely delivery .</li> 
                            </ul>                     
                    </div>
                </div>
            </div>
        </>
    )

}


export default IndustrialAbrasive;
