import React, { useEffect, useRef } from 'react';
import { debounce } from 'lodash';

function useResizeObserver(debounceDelay = 100) {
  const observerRef = useRef(null);
  const targetRef = useRef(null);

  const handleResize = debounce((entries) => {
    // Your logic to handle resizing here
    console.log('Resize event:', entries);
  }, debounceDelay);

  useEffect(() => {
    if (!targetRef.current) return;

    observerRef.current = new ResizeObserver(handleResize);
    observerRef.current.observe(targetRef.current);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return targetRef;
}

export default useResizeObserver;
