import React from 'react'
import '../../style/ApplicationDetail.css'
import company from '../../assets/Applications/Tiles/Large.jpg'

const Tiles = () => {
    return (
        <>
            <div class="container">
                <div class="row application-detail">
                    <div class="col-md-6">
                        <img src={company} alt="" />
                    </div>
                    <div class="col-md-6">
                        <h3>TILES</h3>
                        <h5>Our high-quality raw minerals cater to the global tile industry, providing the essential elegance and durability. </h5>
                        <p>Clay is an essential material in the tile industry and is widely used in various aspects of tile production. It forms 
                        the tile’s body, providing strength, durability, and moisture resistance. With various shaping techniques, clay allows for intricate designs and patterns. Glazing with clay-based mixtures adds a protective layer and decorative finish. The material offers various textures and finishes, and its thermal insulation properties aid in regulating temperature. Its versatility, aesthetics, and eco-friendliness contribute to its popularity in the tile industry</p>
                        <p>Dashmesh Minerals understands the challenges of this industry and has been coming up with raw minerals solutions through the following:</p>
                    <ul>
                            <li>	Stringent quality control yields refined raw mineral output  . </li>
                            <li>	Able to manage vast volumes of raw minerals through cutting-edge processing plants . </li>
                            <li>	An agile approach facilitates the integration of evolving market trends . </li>
                            <li>	Robust logistics network offering even last-mile delivery to industries . </li>

                        </ul>
                    </div>
                </div>
            </div>
        </>
    )

}


export default Tiles;
