import React from "react";
import emailjs from "emailjs-com";
import {Row, Col, Button, Form, Input, InputNumber, message, notification} from "antd";
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const Feedback = () => {
    const [form] = Form.useForm();
    
    const onFinish = (values) => {
        console.log(values);
        // Replace these values with your EmailJS credentials and template ID
        const serviceId = "service_ef2h6hj";
        const templateId = "template_yzgo71k";
        const userId = "CbJmFbX5Rzn90GTkA";

        emailjs
        .send(serviceId, templateId, values, userId)
        .then((response) => {
            console.log("Email sent successfully!", response);
             notification.success({
                message: 'Success',
                placement:'top',
                description: 'Your Feedback has been recieved succesfully',
                duration: 3, // Duration in seconds, or null to keep open until closed manually
              });
              form.resetFields();
        })
        .catch((error) => {
            console.error("Error sending email:", error);
        });
    };

    return (
        <div className="container">
            <div class="row discover-heading">
                <div class="col-md-12">
                    <h3>FEEDBACK</h3>
                </div>
                <div className="col-md-12">
                    <p>Tell us how we can improve. Give your valuable feedback here!</p>
                </div>
            </div>
            <Row className=" justify-content-center">
                <Form
                    name="nest-messages"
                    className="enquiryForm"
                    onFinish={onFinish}
                    style={{
                        maxWidth: 800,
                    }}
                    form={form}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Your Name *" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="user_email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "The input is not valid E-mail!",
                                    },
                                    {
                                        required: true,
                                        message: "Please input your E-mail!",
                                    },
                                ]}
                            >
                                <Input placeholder="Your Email *" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your phone number!",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Your phone No *"
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="profession">
                                <Input placeholder="Profession" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="feedback">
                                <Input.TextArea placeholder="Feedback" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <Form.Item className="onSubmit">
                                <Button type="secondary" style={{border: "2px black solid"}} htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </div>
    );
};

export default Feedback;
