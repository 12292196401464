import React from "react";
import {Link} from "react-router-dom";
import research from "../../assets/R & D/r&d.jpeg";
import "../../style/AboutUs.css";

const RND = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12 mb-4 mt-4 data">
                        <img src={research} alt="" />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-4 mt-5 research">
                        <h3>RESEARCH & DEVELOPMENT</h3>
                        <p>
                            We are resourced with a well-equipped Research and Development Department that ensures the
                            quality and performance of ceramic minerals. Every product of Dashmesh Minerals is a result
                            of flawless R&D set-up. At our testing laboratory, products are tested for their quality at
                            every single level of manufacturing before they are dispatched to the clients. This unit
                            works efficiently to bring in new developments in the products. Our facilities also include
                            systems and process to meet range of mineral with required treatment as per specification by
                            customers.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RND;
