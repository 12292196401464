import {Country, State, City} from "country-state-city";
import Select from "react-select";
import {Form, Button, Row, Input, Col, notification} from "antd";
import emailjs from "emailjs-com";
import {useEffect, useState} from "react";
import {enquiryOptions} from "../../common/common.js";
import "../../style/ContactUs.css";
import {useForm} from "antd/lib/form/Form";
const Enquiry = () => {
    const [form] = useForm();
    const [product, setProduct] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [enquiryType, setEnquiryType] = useState("");
    useEffect(() => {
        console.log(selectedCountry);
        console.log(selectedCountry?.isoCode);
        console.log(State?.getStatesOfCountry(selectedCountry?.isoCode));
    }, [selectedCountry]);

    const onFinish = (values) => {
        console.log(values);
        // Replace these values with your EmailJS credentials and template ID
        const serviceId = "service_ef2h6hj";
        const templateId = "template_haobn6k";
        const userId = "CbJmFbX5Rzn90GTkA";

        emailjs
        .send(serviceId, templateId, values,userId)
        .then((response) => {
            console.log("Email sent successfully!", response);
            notification.success({
                message: 'Success',
                placement:'top',
                description: 'Your Feedback has been recieved succesfully',
                duration: 3, // Duration in seconds, or null to keep open until closed manually
              });
              form.resetFields();
        })
        .catch((error) => {
            console.error("Error sending email:", error);
        });
        
    };

    return (
        <div className="container">
            <div class="row discover-heading">
                <div class="col-md-12">
                    <h3>PRODUCT ENQUIRY</h3>
                </div>
                <div className="col-md-12">
                    <p>Send in your query and get to know more about our products.</p>
                </div>
            </div>
            <Row className=" justify-content-center">
                <Form
                    name="enquiryForm"
                    className="enquiryForm"
                    onFinish={onFinish}
                    style={{
                        width: 800,
                    }}
                    form={form}
                >
                    <Row gutter={[16, 0]}>
                        {" "}
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="products"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select Product Option !",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select Product *"
                                    options={enquiryOptions.selectProducts}
                                    getOptionLabel={(options) => {
                                        return options["item"];
                                    }}
                                    getOptionValue={(options) => {
                                        return options["item"];
                                    }}
                                    value={product}
                                    onChange={(item) => {
                                        setProduct(item);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="firstName" rules={[{required: true}]}>
                                <Input placeholder="First Name *" />
                            </Form.Item>
                            <Form.Item name="firmName" rules={[{required: true}]}>
                                <Input placeholder="Firm Name *" />
                            </Form.Item>
                            <Form.Item name="number" rules={[{required: true}]}>
                                <Input placeholder="Contact Number *" />
                            </Form.Item>
                            <Form.Item name="email" rules={[{required: true}]}>
                                <Input placeholder="Email ID *" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="address" rules={[{required: true}]}>
                                <Input.TextArea className="ml-1" placeholder="Detailed Address" rows={5} />
                            </Form.Item>
                            <Form.Item
                                name="country"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select your country!",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select Country *"
                                    options={Country.getAllCountries()}
                                    getOptionLabel={(options) => {
                                        return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                        return options["name"];
                                    }}
                                    value={selectedCountry}
                                    onChange={(item) => {
                                        setSelectedCountry(item);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="state"
                                className="ml-1"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select your state!",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select State *"
                                    options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                    getOptionLabel={(options) => {
                                        return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                        return options["name"];
                                    }}
                                    value={selectedState}
                                    onChange={(item) => {
                                        setSelectedState(item);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="city"
                                className="ml-1"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select your City!",
                                    },
                                ]}
                            >
                                <Select
                                    className="enquiryForm"
                                    placeholder="Select City *"
                                    options={City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode)}
                                    getOptionLabel={(options) => {
                                        return options["name"];
                                    }}
                                    getOptionValue={(options) => {
                                        return options["name"];
                                    }}
                                    value={selectedCity}
                                    onChange={(item) => {
                                        setSelectedCity(item);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="comments" rules={[{required: true}]}>
                                <Input.TextArea className="ml-1" placeholder="Comments" rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col>
                            <Form.Item className="onSubmit">
                                <Button htmlType="submit">Submit</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </div>
    );
};

export default Enquiry;
