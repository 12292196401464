import React from "react";
import ".././../style/Application.css";
import {Link} from "react-router-dom";
import ReSmall from "../../assets/Applications/Refractories/Small.jpg";
import AgriSmall from "../../assets/Applications/Agriculture Industries/Small.jpg";
import HeavySmall from "../../assets/Applications/Heavy Engineering/Small.jpg";
import IndusSmall from "../../assets/Applications/Industrial Abrasives/Small.jpg";
import SaSmall from "../../assets/Applications/Sanitary Ware/Small.jpg";
import TileSmall from "../../assets/Applications/Tiles/Small.jpg";
import bullet1 from "../../assets/Home/Bullet Images/1.png";
import bullet2 from "../../assets/Home/Bullet Images/2.png";
import bullet3 from "../../assets/Home/Bullet Images/3b.png";
import bullet4 from "../../assets/Home/Bullet Images/4.png";
import bullet5 from "../../assets/Home/Bullet Images/5.png";
import bullet6 from "../../assets/Home/Bullet Images/6.png";

import gif from "../../assets/GIF for Infrastructure-20230712T123339Z-001/GIF for Infrastructure/manmeet dashmesh gif.gif";
const Applications = () => {
    return (
        <>
            <div className="container ">
            <div className="NewApplication">
                <h3>Various Applications We Serve</h3>
                <p>
                    Through our extensive range of products, we cater to a diverse range of industries in India and
                    across the world.
                </p>
                <div className="row">
                    <div className=" col-md-4 col-sm-12 appLink">
                        <Link to="refractories">
                            {/* <div className="icon-f">
                            <img className='jhanda' src="https://i0.wp.com/jldminerals.com/wp-content/uploads/2022/06/refractories-icon.png?fit=60%2C60&ssl=1" alt="" />
                                </div> */}

                            <img src={ReSmall} alt="" />
                            <h4>Refractories</h4>
                        </Link>
                    </div>
                    {/* <div className="col-md-4 col-sm-12">
                        <div className="icon-f">
                            <img className='jhanda' src="https://i0.wp.com/jldminerals.com/wp-content/uploads/2022/06/Sanitary-ware-icon.png?fit=60%2C60&ssl=1" alt="" />
                                </div> */}

                    <div className="col-md-4 col-sm-12 appLink">
                        <Link to="sanitary-ware">
                            <div className="image">
                                <img src={SaSmall} alt="" />
                            </div>
                            <div className="content">
                                <h4>Sanitary Ware</h4>
                            </div>
                        </Link>
                    </div>

                    {/* <div className=" col-md-4 col-sm-12">
                        <div className="icon-f">
                            <img className='jhanda' src="https://i0.wp.com/jldminerals.com/wp-content/uploads/2022/06/Tiles-icon-1.png?fit=60%2C60&ssl=1" alt="" />
                                </div> */}

                    <div className=" col-md-4 col-sm-12 appLink">
                        <Link to="tiles">
                            <div className="image">
                                <img src={TileSmall} alt="" />
                            </div>
                            <div className="content">
                                <h4>Tiles</h4>
                            </div>
                        </Link>
                    </div>

                    {/* </div> */}
                </div>
                <div className="row">
                    <div className=" col-md-4 col-sm-12 appLink">
                        <Link to="agriculture">
                            {/* <div className="icon-f">
                            <img className='jhanda' src="https://i0.wp.com/jldminerals.com/wp-content/uploads/2022/06/dinnerware-icon.png?fit=60%2C60&ssl=1" alt="" />
                                </div> */}
                            <div className="image">
                                <img src={AgriSmall} alt="" />
                            </div>
                            <div className="content">
                                <h4>Agriculture</h4>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4 col-sm-12 appLink">
                        <Link to="heavy-engineering">
                            {/* <div className="icon-f">
                            <img className='jhanda' src="https://i0.wp.com/jldminerals.com/wp-content/uploads/2022/11/Big-industries.png?fit=60%2C59&ssl=1" alt="" />
                                </div> */}
                            <div className="image">
                                <img src={HeavySmall} alt="" />
                            </div>
                            <div className="content">
                                <h4>Heavy Engineering</h4>
                            </div>
                        </Link>
                    </div>
                    <div className=" col-md-4 col-sm-12 appLink">
                        <Link to="industrial-abrasives">
                            {/* <div className="icon-f">
                            <img className='jhanda' src="https://i0.wp.com/jldminerals.com/wp-content/uploads/2022/11/Addeptives-1-1.png?fit=60%2C59&ssl=1" alt="" />
                                </div> */}
                            <div className="image">
                                <img src={IndusSmall} alt="" />
                            </div>
                            <div className="content">
                                <h4>Industrial Abrasives</h4>
                            </div>
                        </Link>
                    </div>
                </div>
                </div>
                <div className="appli-strength">
                <h1>OUR STRENGTHS</h1>
                </div>
                <div className="application-strengths mb-4">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        
                            <h2>why we are better</h2>
                            <h4>
                                Our partnership with our clients is based on mutual trust and we do what is best for our
                                clients due to following:
                            </h4>
                            <h3>
                                <span>
                                    <img src={bullet1} alt="" srcset="" className="bullet-image mb-1" />
                                </span>{" "}
                                Highly Competent team
                                <br />
                                <span>
                                    <img src={bullet2} alt="" srcset="" className="bullet-image mb-1" />
                                </span>{" "}
                                Robust Infrastructure
                                <br />
                                <span>
                                    <img src={bullet3} alt="" srcset="" className="bullet-image mb-1" />
                                </span>{" "}
                                Hi-Tech Processing Plant
                                <br />
                                <span>
                                    <img src={bullet4} alt="" srcset="" className="bullet-image mb-1" />
                                </span>{" "}
                                Widespread logistic network
                                <br />
                                <span>
                                    <img src={bullet5} alt="" srcset="" className="bullet-image mb-1" />
                                </span>{" "}
                                Streamlined supply chain
                                <br />
                                <span>
                                    <img src={bullet6} alt="" srcset="" className="bullet-image mb-1" />
                                </span>{" "}
                                Quality Assured
                            </h3>
                        </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="gif">
                            <img src={gif} alt="" />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Applications;
