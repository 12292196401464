import { BrowserRouter } from "react-router-dom";
import PageLayout from "./component/Layout/Layout";

const App = () => {

  return (
  <BrowserRouter>
      <PageLayout />
    </BrowserRouter>
  );
}

export default App;
